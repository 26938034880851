function BrokeragelicenseMwali() {
  const services = [
    'Stock Brokerage',
    'Securities Brokerage',
    'Financial Advisory',
    'Asset Management',
    'Fund Management',
    'Custody Services',
    'Transaction Clearing',
    'Payment Processing',
    'Currency Trading (FOREX)',
    'Issuance of Securities',
    'Underwriting of Securities',
  ];
  const requirements = [
    'Notarised copies of operative pages of passport for all persons',
    'Current bank reference ideally the reference should reflect a satisfactory relationship for all persons',
    'Address confirmation by copy of recent utility bill or recent bank statement or major credit card bill (2 different documents) for all persons',
    'The IBC applying for a license should have a minimum capital of EUR 250,000. This capital requirement can be satisfied with a financial guarantee.',
    'Business plan.',
  ];
  const steps = [
    'Fill out our application form and prepare all above-mentioned documents for all persons',
    'Send us all required documents by fax or email so that we can check them',
    'We will issue an invoice with the application fee',
    'When we receive the payment, we will start to incorporate the company. It will take up to 4 weeks.',
    'We will send you all the company documents at the time when we receive the annual fee payment.',
  ];
  return (
    <div>
      <h2 className="py-8">BROKERAGE LICENSE MWALI</h2>
      <h3 className="pb-4 font-semibold">BROKERAGE AND FOREX LICENSE IN MWALI</h3>

      <p className="text-1 pb-8">
        If you aim to establish a brokerage or forex business, we offer comprehensive support to navigate the licensing
        process with ease. Our expertise ensures a seamless transition from application to operation, focusing on
        regulatory compliance and operational efficiency. Our services encompass all aspects of brokerage and forex
        license acquisition, providing you with a strong foundation for success.
      </p>

      <h3 className="pb-4 font-semibold">WHY OBTAIN A BROKERAGE AND FOREX LICENSE IN MWALI?</h3>

      <h4 className="font-semibold pb-3">Regulatory Flexibility</h4>
      <p className="text-1 pb-8">
        Offshore jurisdictions often have streamlined regulatory frameworks, making it easier to obtain and maintain a
        brokerage or forex license compared to more stringent onshore jurisdictions. This flexibility allows for quicker
        setup and the ability to adapt swiftly to changing market conditions. Offshore regulatory bodies are typically
        more open to innovative financial products and services, fostering growth and innovation.
      </p>

      <h4 className="font-semibold pb-3">Tax Optimization</h4>
      <p className="text-1 pb-8">
        Offshore brokerage and forex firms may benefit from favorable tax regimes, allowing for greater efficiency in
        tax planning and optimization. These jurisdictions often offer lower tax rates or even tax exemptions on certain
        types of income, enabling your business to achieve significant cost savings. By strategically planning your tax
        obligations, you can enhance profitability and reinvest savings into expanding your services and client base.
      </p>

      <h4 className="font-semibold pb-3">Confidentiality</h4>
      <p className="text-1 pb-8">
        Many offshore jurisdictions prioritize client confidentiality, providing a secure environment for trading
        operations and client transactions. This high level of privacy helps protect client information from
        unauthorized access and potential threats. The commitment to confidentiality fosters trust and reliability,
        attracting clients who value discretion and security in their financial dealings.
      </p>

      <h4 className="font-semibold pb-3">Global Reach</h4>
      <p className="text-1 pb-8">
        By establishing an offshore brokerage or forex firm, you gain access to international markets and can cater to
        clients from around the world. This global reach enhances your business opportunities and revenue potential,
        positioning your firm as a key player in the international financial landscape. Offshore firms can offer
        services in multiple currencies and facilitate cross-border transactions with ease, expanding your customer base
        and service offerings.
      </p>

      <h3 className="pb-4 font-semibold">
        THE INTERNATIONAL BROKERAGE & CLEARING HOUSE LICENSE ALLOW FOR THE LICENSE HOLDER TO ENGAGE IN ACTIVITIES SUCH
        AS:
      </h3>
      <div className="text-1">
        <ul className="pl-5 ml-2 pb-4">
          {services?.map((item, index) => (
            <li
              key={index}
              className="pb-1 relative before:content-['•'] before:font-bold before:absolute before:left-[-15px] before:text-black">
              {item}
            </li>
          ))}
        </ul>
      </div>
      <p className="text-1 pb-4">
        {' '}
        In order to apply for a license the applicant must first establish an International Business Company (IBC) in
        MWali. The IBC should have a minimum of one director who can be of any nationality and resident anywhere. The
        IBC should have a minimum of one shareholder which can be an individual also acting as director or a Company
        registered in any other jurisdiction. This capital requirement can be satisfied with a financial guarantee.
      </p>
      <p className="text-1 pb-8">
        If you wish to go ahead with the formation of a company you have to meet certain due diligence.
      </p>

      <h4 className="font-semibold pb-3">
        We will proceed with the formation and incorporation procedures no documents will be forwarded to the applicant
        until the following due diligence materials have been received:
      </h4>
      <div className="text-1 ">
        <ul className="pl-5 ml-2 pb-8">
          {requirements?.map((item, index) => (
            <li
              key={index}
              className="pb-1 relative before:content-['•'] before:font-bold before:absolute before:left-[-15px] before:text-black">
              {item}
            </li>
          ))}
        </ul>
      </div>
      <h4 className="font-semibold pb-3">Ordering procedure:</h4>
      <div className="text-1 pb-8">
        <ul className="pl-5 ml-2 pb-4 mb-20">
          {steps?.map((item, index) => (
            <li
              key={index}
              className="pb-1 relative before:content-['•'] before:font-bold before:absolute before:left-[-15px] before:text-black">
              {item}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default BrokeragelicenseMwali;
