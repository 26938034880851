import React from 'react';
import { Route, Routes } from 'react-router-dom';
import AboutUs from './pages/aboutus';
import ContactUs from './pages/contactus';
import ForexAndBrokage from './pages/forexandbrokage';
import BrokeragelicenseWesternSahara from './pages/brokeragelicenseWesternSahara';
import BrokeragelicenseMwali from './pages/brokeragelicenseMwali';
import BrokeragelicenseCyprus from './pages/brokeragelicenseCyprus';
import BrokeragelicenseAnjouan from './pages/brokeragelicenseAnjouan';
import BrokeragelicenseSeychelle from './pages/brokeragelicenseSeychelle';

function Router() {
  return (
    <Routes>
      <Route path="/" element={<AboutUs />} />
      <Route path="/contactus" element={<ContactUs />} />
      <Route path="/forexandbrokage" element={<ForexAndBrokage />} />
      <Route path="/brokeragelicenseWesternSahara" element={<BrokeragelicenseWesternSahara />} />
      <Route path="/brokeragelicenseMwali" element={<BrokeragelicenseMwali />} />
      <Route path="/brokeragelicenseAnjouan" element={<BrokeragelicenseAnjouan />} />
      <Route path="/brokeragelicenseCyprus" element={<BrokeragelicenseCyprus />} />
      <Route path="/brokeragelicenseSeychelle" element={<BrokeragelicenseSeychelle />} />
    </Routes>
  );
}
export default Router;
