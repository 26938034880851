import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import Router from './Router';
import { Navbar } from './components/navbar';
import { Footer } from './components/footer';

function App() {
  return (
    <BrowserRouter>
      <div className="flex flex-col min-h-screen">
        <Navbar />
        <div className="flex-grow px-5 lg:px-20">
          <Router />
        </div>
        <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;
