import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import logoWhite from '../assets/logo-white.png';

export function Footer() {
  const [isBrokerageOpen, setIsBrokerageOpen] = useState(false);

  const toggleBrokerageDropdown = () => {
    setIsBrokerageOpen(prev => !prev);
  };
  const navLinks = [
    { name: 'ABOUT US', id: '' },
    { name: 'FOREX AND BROKERAGE LICENSE', id: 'forexandbrokage' },
    { name: 'BROKERAGE', id: 'brokerage', dropdown: true },
    { name: 'CONTACT US', id: 'contactus' },
  ];
  const brokerageLinks = [
    { name: 'Brokerage license Western Sahara', id: 'brokeragelicenseWesternSahara' },
    { name: 'Brokerage license Mwali', id: 'brokeragelicenseMwali' },
    { name: 'Brokerage license Anjouan', id: 'brokeragelicenseAnjouan' },
    { name: 'Brokerage license Cyprus', id: 'brokeragelicenseCyprus' },
    { name: 'Brokerage license Seychelle', id: 'brokeragelicenseSeychelle' },
  ];

  return (
    <footer className="pt-[80px] pb-[40px] min-h-[378px] bg-customDark">
      <div className="flex flex-col lg:flex-row items-start justify-between lg:px-20">
        <div className="flex flex-col items-start gap-y-4 lg:w-1/2">
          <NavLink to="/">
            <img className="max-w-[194px] lg:max-w-[350px] px-4 lg:px-0" src={logoWhite} alt="Logo" />
          </NavLink>
          <p className="hidden lg:block text-[14px] font-medium text-customWhite">Forex license zone LTD</p>
          <p className="hidden lg:block  text-[14px] font-medium text-customWhite">
            167-169 Great Portland Street, London, England, W1W 5PF
          </p>
        </div>

        <ul className="flex flex-col items-start gap-y-[32px] px-6 pt-[56px] text-customWhite lg:w-1/2 lg:pt-0 ">
          {navLinks.map(item => (
            <div key={item.id} className="relative">
              <NavLink
                to={item.id === 'brokerage' ? undefined : `/${item.id}`}
                className="text-customWhite font-krona text-[7px] lg:text-xs leading-custom tracking-wide py-2"
                onClick={item.dropdown ? toggleBrokerageDropdown : undefined}>
                {item.name}
                {item.dropdown && (
                  <span
                    style={{
                      transition: 'transform 0.3s ease-in-out',
                      transform: isBrokerageOpen
                        ? 'rotate(180deg) scaleX(1.5) scaleY(0.7)'
                        : 'rotate(0deg) scaleX(1.5) scaleY(0.7)',
                    }}
                    className="inline-block ml-1">
                    ▼
                  </span>
                )}
              </NavLink>
              {item.dropdown && isBrokerageOpen && (
                <div className="absolute left-[70px] lg:left-[100px] mt-[-30px] w-[200px] lg:w-[300px] bg-customDark z-50 rounded-md shadow-lg">
                  {brokerageLinks.map(brokerageItem => (
                    <NavLink
                      to={`/${brokerageItem.id}`}
                      className="block text-customWhite font-krona text-[7px] lg:text-xs leading-custom tracking-wide py-2 px-4 hover:bg-slate-700"
                      key={brokerageItem.id}
                      onClick={() => {
                        setIsBrokerageOpen(false);
                      }}>
                      {brokerageItem.name}
                    </NavLink>
                  ))}
                </div>
              )}
            </div>
          ))}
        </ul>
      </div>

      <div className="flex flex-col px-20 pt-[7rem] items-center lg:hidden">
        <p className="text-[10px] font-normal pb-4 text-customWhite text-center">Forex license zone LTD</p>
        <p className="text-[10px] font-normal text-customWhite text-center">
          167-169 Great Portland Street, London, England, W1W 5PF
        </p>
      </div>
    </footer>
  );
}
