function ContactUs() {
  return (
    <div>
      <div className="flex justify-end pl-10 pr-0 text-right text-customWhite font-krona font-normal text-[22px] lg:text-[50px]">
        FOREX LICENSE ZONE.
      </div>
      <div>
        <h2 className="py-8">CONTACT US</h2>
        <h3 className="pb-16  font-semibold">FOREX LICENSE ZONE LTD</h3>
        <h3 className="pb-16 font-semibold">DIRECTOR - CLINTON KORIR</h3>
        <h3 className="pb-16 font-semibold">COMPANY NUMBER - 15948829</h3>
        <h3 className="pb-4 mb-20 font-semibold">
          COMPANY ADDRESS: 167-169 GREAT PORTLAND STREET, LONDON, ENGLAND, W1W 5PF
        </h3>
      </div>
    </div>
  );
}

export default ContactUs;
