import React, { useState } from 'react';
import Menu from '../assets/icons/menu.png';
import Close from '../assets/icons/menu-close.png';
import logoBlack from '../assets/logo-black.png';
import { NavLink } from 'react-router-dom';

const navLinks = [
  { name: 'ABOUT US', id: '' },
  { name: 'FOREX AND BROKERAGE LICENSE', id: 'forexandbrokage' },
  { name: 'BROKERAGE', id: 'brokerage', dropdown: true },
  { name: 'CONTACT US', id: 'contactus' },
];

const brokerageLinks = [
  { name: 'Brokerage license Western Sahara', id: 'brokeragelicenseWesternSahara' },
  { name: 'Brokerage license Mwali', id: 'brokeragelicenseMwali' },
  { name: 'Brokerage license Anjouan', id: 'brokeragelicenseAnjouan' },
  { name: 'Brokerage license Cyprus', id: 'brokeragelicenseCyprus' },
  { name: 'Brokerage license Seychelle', id: 'brokeragelicenseSeychelle' },
];

export function Navbar() {
  const [isOpen, setIsOpen] = useState(false);
  const [isBrokerageOpen, setIsBrokerageOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(prev => !prev);
    if (isOpen) {
      setIsBrokerageOpen(false);
    }
  };

  const toggleBrokerageDropdown = () => {
    setIsBrokerageOpen(prev => !prev);
  };

  return (
    <nav className="flex w-full items-center justify-between px-[20px] py-[50px] bg-custombackground lg:mx-auto lg:px-20 ">
      <div className="flex items-center">
        <NavLink to="/">
          <img className="w-[194px] lg:w-[322px]" src={logoBlack} alt="Logo" />
        </NavLink>

        <div className="hidden lg:flex pl-[200px] gap-x-[50px]">
          {navLinks.map(item => (
            <div key={item.id} className="relative">
              <NavLink
                to={item.id === 'brokerage' ? undefined : `/${item.id}`}
                className="flex items-center text-[#36485C] font-krona text-xs leading-custom tracking-wide"
                onClick={item.dropdown ? toggleBrokerageDropdown : undefined}>
                {item.name}
                {item.dropdown && (
                  <span
                    style={{
                      transition: 'transform 0.3s ease-in-out',
                      transform: isBrokerageOpen
                        ? 'rotate(180deg) scaleX(1.5) scaleY(0.7)'
                        : 'rotate(0deg) scaleX(1.5) scaleY(0.7)',
                    }}
                    className="inline-block ml-1">
                    ▼
                  </span>
                )}
              </NavLink>
              {item.dropdown && isBrokerageOpen && (
                <div className="absolute left-0 w-[300px] bg-custombackground z-50 mt-2 rounded-md shadow-lg">
                  {brokerageLinks.map(brokerageItem => (
                    <NavLink
                      to={`/${brokerageItem.id}`}
                      className="block text-customDark font-krona text-xs leading-custom tracking-wide py-2 px-4 hover:bg-gray-100"
                      key={brokerageItem.id}
                      onClick={() => {
                        setIsBrokerageOpen(false);
                      }}>
                      {brokerageItem.name}
                    </NavLink>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>

      <div className="flex items-center lg:hidden">
        <img
          src={isOpen ? Close : Menu}
          className="w-[33px]"
          alt={isOpen ? 'Close Menu' : 'Open Menu'}
          onClick={toggleMenu}
        />
      </div>

      {isOpen && (
        <div className="absolute top-[10px] left-0 w-full bg-white z-50 lg:hidden">
          <div className="flex justify-end items-center px-4 py-3">
            <img src={Close} className="w-[33px]" alt="Close Menu" onClick={toggleMenu} />
          </div>
          <div className="flex flex-col items-start pb-[70px] pl-4">
            {navLinks.map(item => (
              <div key={item.id} className="relative">
                <NavLink
                  to={item.id === 'brokerage' ? undefined : `/${item.id}`}
                  className="flex items-center text-customDark font-krona text-[7px] py-5 font-normal leading-custom tracking-wide"
                  onClick={() => {
                    if (item.dropdown) {
                      toggleBrokerageDropdown();
                    } else {
                      setIsOpen(false);
                    }
                  }}>
                  {item.name}
                  {item.dropdown && (
                    <span
                      style={{
                        transition: 'transform 0.3s ease-in-out',
                        transform: isBrokerageOpen
                          ? 'rotate(180deg) scaleX(1.5) scaleY(0.7)'
                          : 'rotate(0deg) scaleX(1.5) scaleY(0.7)',
                      }}
                      className="inline-block ml-1">
                      ▼
                    </span>
                  )}
                </NavLink>
                {item.dropdown && isBrokerageOpen && (
                  <div className="absolute left-[100%] w-[200px] top-0 lg:w-[300px]  z-50 mt-2 rounded-md shadow-lg">
                    {brokerageLinks.map(brokerageItem => (
                      <NavLink
                        to={`/${brokerageItem.id}`}
                        className="block text-customDark font-krona text-[7px] lg:text-xs leading-custom tracking-wide py-2 px-4 hover:bg-gray-100"
                        key={brokerageItem.id}
                        onClick={() => {
                          setIsBrokerageOpen(false);
                          setIsOpen(false);
                        }}>
                        {brokerageItem.name}
                      </NavLink>
                    ))}
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      )}
    </nav>
  );
}
