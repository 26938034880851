function AboutUs() {
  return (
    <div>
      <div className="flex justify-end pl-10 pr-0 text-right text-customBlue font-krona font-normal text-[22px] lg:text-[50px]">
        FOREX LICENSE ZONE.
      </div>
      <div>
        <h2 className="py-8">ABOUT US</h2>
        <h3 className="pb-4 font-semibold">OUR MAIN TASKS:</h3>

        <p className="text-1 pb-8">
          Help clients reduce tax liabilities through legal methods.
          <br />
          Safeguard client assets from potential risks.
          <br />
          Offer informed advice derived from an in-depth review of the state’s legal system to improve client financial
          outcomes.
          <br />
          Provide assistance with pre-trial or court settlements of corporate disputes across various jurisdictions.
          <br />
          Maintain support for client businesses throughout all phases of their operations.
        </p>

        <h3 className="pb-4 font-semibold">OUR SPECIALISTS:</h3>

        <p className="text-1 pb-8">
          Provide customized solutions based on the specific situation;
          <br />
          Offer customers only legally sound and optimal solutions for a specific situation;
          <br />
          Warn the client in advance about the possible consequences of the decisions made;
          <br />
          Keep customer data confidential;
          <br />
          We act strictly in accordance with the rules of attorney ethics;
          <br />
          We are constantly improving the knowledge and skills of applying Ukrainian legislation and a number of laws of
          other countries.
        </p>

        <h3 className="pb-4 font-semibold">WHAT ARE OUR ADVANTAGES:</h3>

        <p className="text-1 pb-4 mb-20">
          The presence of lawyers in our company provides additional guarantees of our professionalism, as well as
          guarantees of confidentiality, because lawyer secrecy applies to any information that a client conveys about
          himself or his activities, information in any form is not subject to disclosure to third parties without the
          client’s consent; <br />
          Our company has a unique experience and knowledge of the specifics of work in most countries around the
          worldWe develop solutions based on the analysis of legislation and look to the future, studying possible
          changes in the laws of specific states. <br />
          Customer safety is most important to us; <br />
          We value every customer
        </p>
      </div>
    </div>
  );
}

export default AboutUs;
