function BrokeragelicenseCyprus() {
  const requirements = [
    'The presence of registered office-premises in the jurisdiction.',
    'The presence in firm of a director with a higher financial education and sufficient experience in such field.',
    'The presence of a well-written concise description of future activities.',
    'Absolute and unconditional compliance with the conditions that are associated with the initial capital.',
    'Its size is at least 125 thousand euros if we are talking about the STP license, and 730 thousand euros if we are talking about the Market Making license.',
  ];
  const docRequired = [
    'Copies of internal and foreign passports;',
    'Documentary evidence of residential addresses;',
    'Letters of recommendation from a reliable banking institution to confirm financial status;',
    'Professional resume;',
    'Statements from a banking institution, which will indicate the presence of a sufficient amount of funds in the account.',
  ];
  const advantages = [
    'Membership in the EU: the legislative and regulatory framework of the state is developing in such a way as to be a single whole and form an integral part of the legislative framework of the European space.',
    'The right to conduct business throughout the European Union.',
    'The state puts forward a clear and transparent requirement list, which is aimed at ensuring and maintaining maximum comfort for a sense of security for businessmen.',
    'A clear system of control over licensed firms.',
    'The regulatory framework is constantly evolving, supplementing and improving.',
    'The issuance of brokerage and investment licenses has been carried out in the jurisdiction for many decades, which is the key to many years of experience.',
    'Qualified and specially trained employees in the labor market.',
  ];
  const procedures = [
    'Registration of a company in the jurisdiction.',
    'Opening a corporate account in a bank institution, necessary for commercial needs.',
    'Introducing initial capital.',
    'Hiring staff.',
    'Rental of premises for office use.',
    'Preparation of the necessary package of documents for filing an application.',
    'Direct application.',
    'With the correct implementation of the previous paragraphs – getting Forex license Cyprus.',
  ];
  const services = [
    'Registration of firms.',
    'Obtaining licenses to conduct activities. We work with all commercial areas.',
    'Collection and preparation of a package of documents.',
    'Opening bank accounts and merchant accounts.',
    'Corporate Services.',
    'Sale of firms and licenses.',
  ];

  return (
    <div>
      <h2 className="py-8">BROKERAGE LICENSE CYPRUS</h2>
      <h3 className="pb-4 font-semibold">BROKERAGE AND FOREX LICENSE IN CYPRUS</h3>

      <p className="text-1 pb-8">
        Forex-trading is making money on currency exchanging deals. Forex-market exists globally and its turnover
        exceeds millions of dollars. Cyprus is one of the first countries to legalize Forex trading and has gone to
        great lengths to keep traders safe by offering them quality, greatly-full and strict regulation. However,
        despite the severity of certain points of regulative framework, Forex-regulation in Cyprus is quite loyal to the
        representatives of such a niche.
      </p>
      <p className="text-1 pb-8">
        Our firm is experts in the field of companies’ licensing and formation in hundreds of jurisdictions around the
        world. We will become your secure allies in getting license to operate in any of your chosen spaces, in
        particular, in getting Forex broker license in Belize, Spain, Malta and others. With us, you do not have to cope
        with all difficulties and nuances that may arise on your own – we’ll take care of this completely.
      </p>

      <h4 className="font-semibold pb-4">Cyprus forex license: main requirements</h4>
      <p className="text-1 pb-8">
        Regulatory body controlling activities of Forex-brokers and directly issues permits to be evolved in this
        direction is Cyprus Securities and Exchange Commission – CySEC. Commercial structures that are interested in the
        official approval of their activities need to meet a number of normative points put forward by the regulator.
      </p>
      <ol className="text-1 pb-8 list-decimal pl-5 ">
        {requirements?.map((item, index) => (
          <li key={index} className="pb-1">
            {item}
          </li>
        ))}
      </ol>

      <p className="text-1 pb-4">
        As already mentioned, Cyprus is included in the list of the first jurisdictions where the Forex markets first
        began to form and develop. The infrastructure for the relevant firms in this country is developed in the best
        possible way, therefore brokers are primarily interested in a brokerage license in Cyprus. Cyprus is often seen
        as a competitor to the BVI and Mauritius. The last of the mentioned jurisdictions is quite popular due to the
        special loyalty and profitability of the local FSC regulator. It is important to note that having obtained a
        Forex license in Cyprus, the licensee must properly prepare and submit financial statements.
      </p>
      <p className="text-1 pb-2">
        As for the package of documents required to get Cyprus Forex license, it consists of a list of the following
        papers that are mandatory for all beneficiaries, directors and shareholders as a minimum set:
      </p>
      <div className="text-1">
        <ul className="pl-5 ml-2 pb-8">
          {docRequired?.map((item, index) => (
            <li
              key={index}
              className="pb-1 relative before:content-['•'] before:font-bold before:absolute before:left-[-15px] before:text-black">
              {item}
            </li>
          ))}
        </ul>
      </div>

      <p className="text-1 pb-8">
        In addition to the above, additional documents may also be requested by the regulator. Our experts will help you
        prepare and collect everything you need.
      </p>

      <h4 className="font-semibold pb-4">Types of forex licenses in Cyprus</h4>
      <p className="text-1 pb-4">
        Within the jurisdiction, there is one single permit for all Forex projects. A Cyprus forex license can be
        obtained by companies classified as CIF, which are classified as participants in the securities market at a
        professional level, but in a broader sense – with coverage of the forex market. After obtaining the necessary
        permission, the organization has the right to manage portfolio investments, engage in investment advice, receive
        and execute orders, work with investments and financial instruments.
      </p>
      <p className="text-1 pb-8">
        Forex license Cyprus opens up many opportunities, in particular, the right to legally reside in any country that
        has EU membership, which increases the reputation and global image of the company. The licensed organization
        will be included in the list of official brokers.
      </p>

      <h4 className="font-semibold pb-4">Advantages of forex licenses in Cyprus</h4>
      <p className="text-1 pb-4">
        Along with such jurisdictions as the BVI and Mauritius, this country also offers a lot of specific advantages.
        The local regulator, as well as the Mauritius regulator FSC, tries to provide maximum security and commercial
        comfort for Forex firms, while setting a loyal requirement list and forming a reliable regulatory framework.
        Forex companies get the opportunity to enjoy the following benefits and privileges.
      </p>
      <div className="text-1">
        <ul className="pl-5 ml-2 pb-4">
          {advantages?.map((item, index) => (
            <li
              key={index}
              className="pb-1 relative before:content-['•'] before:font-bold before:absolute before:left-[-15px] before:text-black">
              {item}
            </li>
          ))}
        </ul>
      </div>
      <p className="text-1 pb-8">
        Our company will provide you with comprehensive professional assistance not only in licensing, but also in
        acquiring a ready-made company in Spain, Portugal, Germany, Poland, Estonia and other jurisdictions around the
        world. We deal with various commercial niches, particularly, cryptocurrency sphere, gambling, etc.
      </p>

      <h3 className="pb-4 font-semibold">Procedure to obtain a forex license in Cyprus</h3>

      <p className="text-1 pb-4">
        The procedure for Forex licensing in Cyprus is quite simple and straightforward. It consists of several
        following steps.
      </p>
      <ol className="text-1 pb-8 list-decimal pl-5 ">
        {procedures?.map((item, index) => (
          <li key={index} className="pb-1">
            {item}
          </li>
        ))}
      </ol>
      <h3 className="pb-4 font-semibold">Taxation of forex companies in Cyprus</h3>

      <p className="text-1 pb-8">
        Speaking about the taxation of Forex structures in Cyprus, it is worth noting the following aspects. The
        jurisdiction has a low corporate tax of 12.5%. The 0% rate applies to income tax. The rate of 19% is valid for
        VAT – one of the lowest. There is no obligation to pay tax on dividends. The jurisdiction has a wide network of
        agreements concluded in order to avoid double taxation.
      </p>

      <h3 className="pb-4 font-semibold">What is included in our services</h3>
      <p className="text-1 pb-4">
        Our company – professionals and experts in the establishment and licensing of companies around the world. We
        work to achieve the most effective results for our clients in the shortest possible time. The list of our
        services includes the following.
      </p>

      <ol className="text-1 pb-8 list-decimal pl-5 ">
        {services?.map((item, index) => (
          <li key={index} className="pb-1">
            {item}
          </li>
        ))}
      </ol>
      <p className="text-1 pb-8">
        We have extensive experience in this area, so contacting us, you can be absolutely calm.
      </p>
      <h3 className="pb-4 font-semibold">How much is it to obtain a forex license in Cyprus?</h3>
      <p className="text-1 pb-4 mb-20">
        The cost of licensing is calculated individually in each case, as it depends on many related factors. Our
        experts will help you correctly calculate the total cost of obtaining a permit, as well as optimize your budget
        as much as possible.
      </p>
    </div>
  );
}

export default BrokeragelicenseCyprus;
