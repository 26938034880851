function BrokeragelicenseAnjouan() {
  const applicationRequirements = [
    'The percentage of ownership by the shareholders',
    'The details of the ultimate beneficial owners (chart may help if many)',
    'Any other useful information to support the application.',
    'CVs of all the directors',
    'Full contact details of the directors and shareholders (KYC questionnaire)',
    'Bank reference for all the beneficial owners',
    'Clear certified passport copies of all beneficial owners',
    'Proof of addresses (utility bills)',
    'Business or legal reference for the beneficial owners',
    'Legal documents for the shareholding companies (articles, certificate of incorporation, etc.)',
    'Latest audited financial statements for all shareholding companies (if any)',
    'A certificate to prove that the beneficial owners do not have a criminal record',
  ];

  return (
    <div>
      <h2 className="py-8">BROKERAGE LICENSE ANJOUAN</h2>
      <h3 className="pb-4 font-semibold">BROKERAGE AND FOREX LICENSE IN ANJOUAN</h3>

      <p className="text-1 pb-8">
        If you aim to establish a brokerage or forex business, we offer comprehensive support to navigate the licensing
        process with ease. Our expertise ensures a seamless transition from application to operation, focusing on
        regulatory compliance and operational efficiency. Our services encompass all aspects of brokerage and forex
        license acquisition, providing you with a strong foundation for success.
      </p>

      <h3 className="pb-4 font-semibold">WHY OBTAIN A BROKERAGE AND FOREX LICENSE IN ANJOUAN?</h3>

      <h4 className="font-semibold pb-3">Regulatory Flexibility</h4>
      <p className="text-1 pb-8">
        Offshore jurisdictions often have streamlined regulatory frameworks, making it easier to obtain and maintain a
        brokerage or forex license compared to more stringent onshore jurisdictions. This flexibility allows for quicker
        setup and the ability to adapt swiftly to changing market conditions. Offshore regulatory bodies are typically
        more open to innovative financial products and services, fostering growth and innovation.
      </p>

      <h4 className="font-semibold pb-3">Tax Optimization</h4>
      <p className="text-1 pb-8">
        Offshore brokerage and forex firms may benefit from favorable tax regimes, allowing for greater efficiency in
        tax planning and optimization. These jurisdictions often offer lower tax rates or even tax exemptions on certain
        types of income, enabling your business to achieve significant cost savings. By strategically planning your tax
        obligations, you can enhance profitability and reinvest savings into expanding your services and client base.
      </p>

      <h4 className="font-semibold pb-3">Confidentiality</h4>
      <p className="text-1 pb-8">
        Many offshore jurisdictions prioritize client confidentiality, providing a secure environment for trading
        operations and client transactions. This high level of privacy helps protect client information from
        unauthorized access and potential threats. The commitment to confidentiality fosters trust and reliability,
        attracting clients who value discretion and security in their financial dealings.
      </p>

      <h4 className="font-semibold pb-3">Global Reach</h4>
      <p className="text-1 pb-8">
        By establishing an offshore brokerage or forex firm, you gain access to international markets and can cater to
        clients from around the world. This global reach enhances your business opportunities and revenue potential,
        positioning your firm as a key player in the international financial landscape. Offshore firms can offer
        services in multiple currencies and facilitate cross-border transactions with ease, expanding your customer base
        and service offerings.
      </p>

      <h3 className="pb-8 font-semibold">Landscape</h3>

      <h3 className="pb-8 font-semibold">Due diligence</h3>

      <h3 className="pb-4 font-semibold">Formation</h3>
      <p className="text-1 pb-8">
        Forming a brokerage is a relatively quick process due to the lack of bureaucracy. Approval can be granted
        swiftly if the necessary requirements are met. There are no requirements for local offices or staff, although we
        would suggest that all companies have this facility which can be arranged at a minimal cost (see Local office
        facility). Application for permission for a Brokerage license should be made to Application Form License.
      </p>

      <h4 className="pb-3 font-semibold">The following documents will be required:</h4>
      <div className="text-1">
        <ul className="pl-5 ml-2 pb-8">
          {applicationRequirements?.map((item, index) => (
            <li
              key={index}
              className="pb-1 relative before:content-['•'] before:font-bold before:absolute before:left-[-15px] before:text-black">
              {item}
            </li>
          ))}
        </ul>
      </div>

      <h4 className="pb-3 font-semibold">KYC AND DUE DILIGENCE</h4>
      <p className="text-1 pb-3">
        A charge is made for each director/shareholder for due diligence (cost is dependent of country of residence), in
        the event that a client is not accepted for a license this payment is not refundable
      </p>
      <p className="text-1 pb-3">
        Please submit the following documents for each of the beneficial owner, director, manager, authorized signatory,
        shareholder/member of the Entity or agent under power of attorney.
      </p>
      <p className="text-1">a) For Individual</p>
      <p className="text-1 pb-3">
        A certified copy of passport or current driving license or national identity card showing the name, date and
        place of birth, nationality, signature of holder and data of issue/expiry. The document must be certified by a
        banker, lawyer or actuary or notary or accountant holding a recognized professional qualification.
      </p>
      <p className="text-1 pb-3">
        Proof of residential address. Acceptable evidence includes an original utility bill, bank or credit card
        statement. Original Bank Reference.
      </p>
      <p className="text-1 pb-3">
        b) For Corporate/Partnership
        <br /> Certificate of incorporation or registration and the memorandum and articles of association or
        constitution or deed of partnership as the case may be. Document has to be certified by banker, lawyer or
        actuary or notary or accountant holding a recognized professional qualification.
      </p>
      <p className="text-1 pb-3">Original Bank Reference.</p>
      <p className="text-1 pb-3">A certified true copy of latest audited account or annual report.</p>
      <p className="text-1 pb-3">A Certificate of good standing.</p>
      <p className="text-1 pb-3">
        List of substantial shareholders (who holds not less than 5% of the voting power exercisable at the meeting of
        shareholder/member) and a list of directors, and corporate structure.
      </p>
      <p className="text-1 pb-4 mb-20">
        A certified copy of Board resolution granting authority to its managers, officers or employees to transact
        business on its behalf.
      </p>
    </div>
  );
}

export default BrokeragelicenseAnjouan;
