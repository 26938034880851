function BrokeragelicenseSeychelle() {
  const licenseNeeds = [
    'Is a domestic company or a company incorporated under the laws of a recognized jurisdiction;',
    'Has at least 2 (two) natural persons appointed as directors; residence requirements are not stipulated;',
    'Has at least 2 (two) shareholders; legal and physical persons can act as shareholders.',
    'Employs at least one licensed Representative of Securities Dealer and a Compliance Officer; the directors can occupy this position;',
    'Has a minimum paid-up capital of US$ 50,000;',
    'Satisfies the FSA that it is a fit and proper person to be licensed as a dealer;',
    'Provides for a separate account to hold clients’ funds;',
    'Has a local office;',
    'Has Professional Indemnity Cover in place.',
  ];

  const timeframes = [
    'Establishment of a Company – 1 week;',
    'Preparation of all necessary documents to apply for the license – approx. 1 month, subject to the specific clients’ needs and the difficulty of a project;',
    'Review of the application by the FSA – 1 month; the period may be extended by the FSA up to 3 months.',
  ];
  const advantages = [
    'Eligibilities of the license holder: the right to engage in securities trading, such as shares, options, bonds, and others, as well as to manage investment portfolios on behalf of a client.',
    'Offshore tax regime.',
    'Seychelles is one of the most popular jurisdictions for the establishment of a brokerage company; all the necessary infrastructure for the establishment of a company and doing business is already present.',
  ];
  const docRequired = [
    'Police Clearance Certificate',
    'Declaration of no history of Bankruptcy',
    'Certified Passport Copy',
    'Original or Certified Copy of Bank Reference Letter',
    '2 Certified copies of Professional Reference Letters',
    'Original or Certified Copy of utility bills',
    'Resume / CV outlining experience in Forex Brokerage Activities and qualifications warranting issuance of a license.',
    'Information on who the target audience and market will be.',
  ];

  return (
    <div>
      <h2 className="py-8">BROKERAGE LICENSE SEYCHELLE</h2>
      <h3 className="pb-4 font-semibold">BROKERAGE AND FOREX LICENSE IN SEYCHELLE</h3>

      <p className="text-1 pb-8">
        If you plan to operate in financial markets and offer FX brokerage services to clients from regulated
        jurisdictions, you must apply for a Forex broker license, and Seychelles, definitely, suits well to get it. This
        license is required for all brokerage firms, mutual and hedge funds.
      </p>

      <h4 className="font-semibold pb-4">Reasons for choosing an offshore jurisdiction</h4>
      <p className="text-1 pb-8">
        Authorities in both offshore and onshore jurisdictions shall grand the license to the entity, which fulfills all
        statutory requirements, but as a rule, it is much more easier to adhere to offshore legal regime, it is
        especially important, in terms of minimum capital requirements. Below, the basic requirements for obtaining
        Seychelles forex license are displayed.
      </p>
      <h4 className="font-semibold pb-4">
        Seychelles Financial Services Authority will not grant a license unless it is satisfied that the applicant:
      </h4>
      <div className="text-1">
        <ul className="pl-5 ml-2 pb-8">
          {licenseNeeds?.map((item, index) => (
            <li
              key={index}
              className="pb-1 relative before:content-['•'] before:font-bold before:absolute before:left-[-15px] before:text-black">
              {item}
            </li>
          ))}
        </ul>
      </div>
      <h4 className="font-semibold pb-4">The advantages of a Seychelles forex license:</h4>
      <div className="text-1">
        <ul className="pl-5 ml-2 pb-8">
          {advantages?.map((item, index) => (
            <li
              key={index}
              className="pb-1 relative before:content-['•'] before:font-bold before:absolute before:left-[-15px] before:text-black">
              {item}
            </li>
          ))}
        </ul>
      </div>

      <h4 className="font-semibold pb-4">
        Seychelles is one of the best jurisdictions for opening a company and obtaining a Forex brokerage license in
        terms of reasonable cost and minimal capital requirements. Prompt timeframes are, also, worth mentioning:
      </h4>
      <div className="text-1">
        <ul className="pl-5 ml-2 pb-8">
          {timeframes?.map((item, index) => (
            <li
              key={index}
              className="pb-1 relative before:content-['•'] before:font-bold before:absolute before:left-[-15px] before:text-black">
              {item}
            </li>
          ))}
        </ul>
      </div>

      <h4 className="font-semibold pb-4">Application procedure:</h4>
      <p className="text-1 pb-4">1. Data collection.</p>
      <p className="text-1 pb-4">
        This is the first stage, at which a comprehensive KYC procedure takes its place, the procedure incudes:
        verification of the identity documents, residence address, the source of funds and the check whether a client
        possess necessary education, qualifications and experience to engage in this business.
      </p>
      <p className="text-1 pb-4">2. Preparation of the application.</p>
      <p className="text-1 pb-4">
        At this stage, application forms to be filled and all necessary documents to be collected, this, also, includes
        drafting of the Articles and Memorandum.
      </p>
      <p className="text-1 pb-4">3. Submission of the application.</p>
      <p className="text-1 pb-4">
        Aster all documents collected and the forms filled, we will submit the application to Seychelles FSA. All
        government fees to be paid at this stage.
      </p>
      <p className="text-1 pb-4">3. Submission of the application.</p>
      <p className="text-1 pb-4">
        Aster all documents collected and the forms filled, we will submit the application to Seychelles FSA. All
        government fees to be paid at this stage.
      </p>

      <h4 className="font-semibold pb-4">4. Further support.</h4>
      <p className="text-1 pb-8">
        Our specialists will be in touch with the FSA and monitor the procedure of application consideration
      </p>

      <h4 className="font-semibold pb-8">Required documnets</h4>
      <h4 className="font-semibold pb-4">
        In accordance with the established requirements, each of the directors or officers, shareholders and
        beneficiaries must provide the following documents:
      </h4>
      <ol className="text-1 pb-4 list-decimal pl-5 ">
        {docRequired?.map((item, index) => (
          <li key={index} className="pb-1">
            {item}
          </li>
        ))}
      </ol>
      <p className="text-1 pb-4">
        All documents must be issued no longer than 3 months prior to the submission, certified and, if required,
        translated into English and apostilled
      </p>

      <p className="text-1 pb-4 mb-20">
        Eternity Law International will provide you with expert advice on obtaining a forex broker license in a suitable
        jurisdiction.
      </p>
    </div>
  );
}

export default BrokeragelicenseSeychelle;
